import "./style.css";
import logo from "./img/logo.svg";
import tg from "./img/tg.svg";
import wt from "./img/wt.svg";
import arrow from "./img/arrow.svg";
import { useEffect, useState } from "react";
import arr from "./img/arr.svg";
import tim from "./img/tim.svg";
import ph from "./img/ph.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import sl from "./img/sl.png";
import sl1 from "./img/sl1.png";
import sls1 from "./img/sls1.svg";
import sls2 from "./img/sls2.svg";
import sls3 from "./img/sls3.svg";
import ge from "./img/ge.svg";
import gogm from "./img/gogm.svg";
import sht from "./img/l.svg";
import shm from "./img/lm.svg";
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-creative";
import tuda from "./img/tuda.svg";
import tudam from "./img/tudam.svg";
import omg from "./img/omg.svg";
import sf from "./img/sf.svg";
import lamp from "./img/lamp.png";
// import required modules
import { EffectCreative } from "swiper/modules";
import { Rotate, Fade, Zoom, Slide } from "react-reveal";
import { useNavigate } from "react-router-dom";
import ReactInputMask from "react-input-mask";
import { sendTg } from "../Api/Api";
export default function Landing() {
  const Navigate = useNavigate();
  const [headSlide, setHeadSlide] = useState(0);
  const [sl2, setsl2] = useState(0);
  const [sl3, setsl3] = useState(0);
  const [sl4, setsl4] = useState(0);
  const [sl5, setsl5] = useState(0);
  const [pop, setPop] = useState(false);
  const [tel, setTel] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 3000);
  }, []);
  const [show, setShow] = useState(false);
  return (
    <div className="land">
      {pop && (
        <Fade duration={"300"}>
          <div
            id="closeArea"
            className="popUp"
            onClick={(e) => {
              e.target.id == "closeArea" && setPop(false);
            }}
          >
            <div className="popUpBody">
              <h3>
                Ваш бизнес онлайн
                <span>легко и быстро!</span>
              </h3>
              <p>
                Оставьте свои контакты, получите консультацию и готовое решение
                в самое ближайшее время.
              </p>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Введите ваше имя*"
                id="name"
              />

              <ReactInputMask
                mask="+7(999)999 99 99"
                value={tel}
                onChange={(e) => setTel(e.target.value)}
                placeholder="Номер телефона*"
                id="tel"
              ></ReactInputMask>
              <input
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
              />
              <button
                onClick={() => {
                  if (name.length < 3) {
                    document.getElementById("name").style.border =
                      "1px solid red";
                  } else {
                    document.getElementById("name").style.border = "none";
                  }
                  if (tel.includes("_") || tel.length == 0) {
                    document.getElementById("tel").style.border =
                      "1px solid red";
                  } else {
                    document.getElementById("tel").style.border = "none";
                  }
                  if (
                    name.length >= 3 &&
                    tel.length > 0 &&
                    !tel.includes("_")
                  ) {
                    sendTg(name, tel, email).then((status) => {
                      Navigate("/спасибо");
                    });
                  }
                }}
              >
                Отправить
              </button>
            </div>
          </div>
        </Fade>
      )}
      <header>
        <div className="autm">
          <img src="./img/k3.svg" className="k3" alt="" />
          <img src="./img/k2.svg" className="k2" alt="" />
          <img src="./img/k1.svg" className="k1" alt="" />
        </div>
        <Fade top>
          <div className="showSliderHeader">
            <img src={logo} className="showLogo" alt="" />
            <p>Задайте ваш вопрос</p>
            <a
              href="https://wa.me/79253338363"
              target="_blank"
              className="showLink"
            >
              {document.body.clientWidth > 640 && " В Whatsapp"}
              <img src={wt} alt="" />
            </a>
            <a
              href="https://t.me/internetvitrina"
              target="_blank"
              className="showLink"
            >
              {document.body.clientWidth > 640 && "  В Telegram"}
              <img src={tg} alt="" />
            </a>
            <div className="showbutton" onClick={() => Navigate("/ниши")}>
              Варианты ниш
            </div>
            <div className="showBusiness" onClick={() => setPop(true)}>
              Запустить бизнес онлайн<span class="flare"></span>
            </div>
            <div className="showContacs">
              <a href="tel:+7(925) 333 83-63">
                <span>+7</span> (925) 333 83-63
              </a>
              <p>с 9:00 до 21:00</p>
            </div>
          </div>
        </Fade>
        <div className="headerBody">
          <div className="headerLeft">
            <img
              src={
                document.body.clientWidth > 640
                  ? "./img/tit.svg"
                  : "./img/tit.svg"
              }
              className="tits"
              alt=""
            />

            <Zoom>
              <h3>
                Для продажи <span>товаров или услуг</span>
              </h3>
            </Zoom>

            <div className="bonus">
              <div className="bonusEm">
                <img
                  src={
                    document.body.clientWidth > 640
                      ? "./img/h1.png"
                      : "./img/om1.png"
                  }
                  alt=""
                />
                <div>
                  <p>От 411 ₽ в день</p>
                  <span>
                    С переходом права <br /> собственности через 1 год
                  </span>
                </div>
              </div>
              <div className="bonusEm">
                <img
                  src={
                    document.body.clientWidth > 640
                      ? "./img/h2.png"
                      : "./img/om2.png"
                  }
                  alt=""
                />
                <div>
                  <p>+25-45%</p>
                  <span>
                    К объему продаж <br /> за 9-12 мес
                  </span>
                </div>
              </div>
            </div>
            {document.body.clientWidth < 640 && (
              <div className="hdRight">
                <Zoom>
                  {" "}
                  <Swiper
                    grabCursor={true}
                    effect={"creative"}
                    creativeEffect={{
                      prev: {
                        shadow: true,
                        translate: [0, 0, -800],
                        rotate: [180, 0, 0],
                      },
                      next: {
                        shadow: true,
                        translate: [0, 0, -800],
                        rotate: [-180, 0, 0],
                      },
                    }}
                    onsw
                    modules={[EffectCreative]}
                    className="mySwiper4"
                    onActiveIndexChange={(index) =>
                      setHeadSlide(index.activeIndex)
                    }
                  >
                    <SwiperSlide>
                      <img src={"./img/н1.png"} alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src={"./img/н2.png"} alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src={"./img/н3.png"} alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src={"./img/н4.png"} alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src={"./img/н5.png"} alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src={"./img/н6.png"} alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src={"./img/н7.png"} alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img
                        style={{ cursor: "pointer" }}
                        onClick={() => Navigate("/ниши")}
                        src={"./img/н8.png"}
                        alt=""
                      />
                    </SwiperSlide>
                  </Swiper>
                </Zoom>
                <Zoom>
                  {" "}
                  <div className="dotser">
                    <div
                      style={
                        headSlide == 0 && document.body.clientWidth > 640
                          ? {
                              width: "0.8vw",
                              height: "0.8vw",
                              background: "#6A7080",
                            }
                          : headSlide == 0 && document.body.clientWidth < 640
                          ? {
                              width: "4.5vw",
                              height: "4.5vw",
                              background: "#6A7080",
                            }
                          : {}
                      }
                    ></div>
                    <div
                      style={
                        headSlide == 1 && document.body.clientWidth > 640
                          ? {
                              width: "0.8vw",
                              height: "0.8vw",
                              background: "#6A7080",
                            }
                          : headSlide == 1 && document.body.clientWidth < 640
                          ? {
                              width: "4.5vw",
                              height: "4.5vw",
                              background: "#6A7080",
                            }
                          : {}
                      }
                    ></div>
                    <div
                      style={
                        headSlide == 2 && document.body.clientWidth > 640
                          ? {
                              width: "0.8vw",
                              height: "0.8vw",
                              background: "#6A7080",
                            }
                          : headSlide == 2 && document.body.clientWidth < 640
                          ? {
                              width: "4.5vw",
                              height: "4.5vw",
                              background: "#6A7080",
                            }
                          : {}
                      }
                    ></div>
                    <div
                      style={
                        headSlide == 3 && document.body.clientWidth > 640
                          ? {
                              width: "0.8vw",
                              height: "0.8vw",
                              background: "#6A7080",
                            }
                          : headSlide == 3 && document.body.clientWidth < 640
                          ? {
                              width: "4.5vw",
                              height: "4.5vw",
                              background: "#6A7080",
                            }
                          : {}
                      }
                    ></div>
                    <div
                      style={
                        headSlide == 4 && document.body.clientWidth > 640
                          ? {
                              width: "0.8vw",
                              height: "0.8vw",
                              background: "#6A7080",
                            }
                          : headSlide == 4 && document.body.clientWidth < 640
                          ? {
                              width: "4.5vw",
                              height: "4.5vw",
                              background: "#6A7080",
                            }
                          : {}
                      }
                    ></div>
                    <div
                      style={
                        headSlide == 5 && document.body.clientWidth > 640
                          ? {
                              width: "0.8vw",
                              height: "0.8vw",
                              background: "#6A7080",
                            }
                          : headSlide == 5 && document.body.clientWidth < 640
                          ? {
                              width: "4.5vw",
                              height: "4.5vw",
                              background: "#6A7080",
                            }
                          : {}
                      }
                    ></div>
                    <div
                      style={
                        headSlide == 6 && document.body.clientWidth > 640
                          ? {
                              width: "0.8vw",
                              height: "0.8vw",
                              background: "#6A7080",
                            }
                          : headSlide == 6 && document.body.clientWidth < 640
                          ? {
                              width: "4.5vw",
                              height: "4.5vw",
                              background: "#6A7080",
                            }
                          : {}
                      }
                    ></div>
                    <div
                      style={
                        headSlide == 7 && document.body.clientWidth > 640
                          ? {
                              width: "0.8vw",
                              height: "0.8vw",
                              background: "#6A7080",
                            }
                          : headSlide == 7 && document.body.clientWidth < 640
                          ? {
                              width: "4.5vw",
                              height: "4.5vw",
                              background: "#6A7080",
                            }
                          : {}
                      }
                    ></div>
                  </div>
                </Zoom>
                <Rotate bottom right>
                  <img src="./img/hand.png" className="hands" alt="" />
                </Rotate>
              </div>
            )}
            <div className="gol">
              <div className="golEm">
                <img src="/img/gol.svg" alt="" />
                <p>
                  Подходит
                  <span>для ЛЮБОГО бизнеса!</span>
                </p>
              </div>
              <div className="golEm">
                <img src="/img/gol.svg" alt="" />
                <p>
                  20+ подключаемых
                  <span>модулей</span>
                </p>
              </div>
              <div className="golEm">
                <img src="/img/gol.svg" alt="" />
                <p>
                  Готовое решение
                  <span>за 24 часа</span>
                </p>
              </div>
            </div>
            <div className="add">
              <div className="showBusinesss" onClick={() => setPop(true)}>
                Запустить бизнес онлайн<span class="flare"></span>
              </div>
              {document.body.clientWidth < 640 && (
                <img
                  src="./img/blya.svg"
                  style={{ width: "100%", marginTop: "5vw" }}
                />
              )}
              <div className="pred">
                <p>Ваша экономия на разработке</p>
                <span>более 1.000.000 рублей!</span>
              </div>
            </div>
          </div>

          {document.body.clientWidth > 640 && (
            <div className="hdRight">
              <Zoom>
                {" "}
                <Swiper
                  grabCursor={true}
                  effect={"creative"}
                  creativeEffect={{
                    prev: {
                      shadow: true,
                      translate: [0, 0, -800],
                      rotate: [180, 0, 0],
                    },
                    next: {
                      shadow: true,
                      translate: [0, 0, -800],
                      rotate: [-180, 0, 0],
                    },
                  }}
                  onsw
                  modules={[EffectCreative]}
                  className="mySwiper4"
                  onActiveIndexChange={(index) =>
                    setHeadSlide(index.activeIndex)
                  }
                >
                  <SwiperSlide>
                    <img src={"./img/н1.png"} alt="" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src={"./img/н2.png"} alt="" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src={"./img/н3.png"} alt="" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src={"./img/н4.png"} alt="" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src={"./img/н5.png"} alt="" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src={"./img/н6.png"} alt="" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src={"./img/н7.png"} alt="" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      style={{ cursor: "pointer" }}
                      onClick={() => Navigate("/ниши")}
                      src={"./img/н8.png"}
                      alt=""
                    />
                  </SwiperSlide>
                </Swiper>
              </Zoom>
              <Zoom>
                <div className="dotser">
                  <div
                    style={
                      headSlide == 0 && document.body.clientWidth > 640
                        ? {
                            width: "0.8vw",
                            height: "0.8vw",
                            background: "#6A7080",
                          }
                        : headSlide == 0 && document.body.clientWidth < 640
                        ? {
                            width: "4.5vw",
                            height: "4.5vw",
                            background: "#6A7080",
                          }
                        : {}
                    }
                  ></div>
                  <div
                    style={
                      headSlide == 1 && document.body.clientWidth > 640
                        ? {
                            width: "0.8vw",
                            height: "0.8vw",
                            background: "#6A7080",
                          }
                        : headSlide == 1 && document.body.clientWidth < 640
                        ? {
                            width: "4.5vw",
                            height: "4.5vw",
                            background: "#6A7080",
                          }
                        : {}
                    }
                  ></div>
                  <div
                    style={
                      headSlide == 2 && document.body.clientWidth > 640
                        ? {
                            width: "0.8vw",
                            height: "0.8vw",
                            background: "#6A7080",
                          }
                        : headSlide == 2 && document.body.clientWidth < 640
                        ? {
                            width: "4.5vw",
                            height: "4.5vw",
                            background: "#6A7080",
                          }
                        : {}
                    }
                  ></div>
                  <div
                    style={
                      headSlide == 3 && document.body.clientWidth > 640
                        ? {
                            width: "0.8vw",
                            height: "0.8vw",
                            background: "#6A7080",
                          }
                        : headSlide == 3 && document.body.clientWidth < 640
                        ? {
                            width: "4.5vw",
                            height: "4.5vw",
                            background: "#6A7080",
                          }
                        : {}
                    }
                  ></div>
                  <div
                    style={
                      headSlide == 4 && document.body.clientWidth > 640
                        ? {
                            width: "0.8vw",
                            height: "0.8vw",
                            background: "#6A7080",
                          }
                        : headSlide == 4 && document.body.clientWidth < 640
                        ? {
                            width: "4.5vw",
                            height: "4.5vw",
                            background: "#6A7080",
                          }
                        : {}
                    }
                  ></div>
                  <div
                    style={
                      headSlide == 5 && document.body.clientWidth > 640
                        ? {
                            width: "0.8vw",
                            height: "0.8vw",
                            background: "#6A7080",
                          }
                        : headSlide == 5 && document.body.clientWidth < 640
                        ? {
                            width: "4.5vw",
                            height: "4.5vw",
                            background: "#6A7080",
                          }
                        : {}
                    }
                  ></div>
                  <div
                    style={
                      headSlide == 6 && document.body.clientWidth > 640
                        ? {
                            width: "0.8vw",
                            height: "0.8vw",
                            background: "#6A7080",
                          }
                        : headSlide == 6 && document.body.clientWidth < 640
                        ? {
                            width: "4.5vw",
                            height: "4.5vw",
                            background: "#6A7080",
                          }
                        : {}
                    }
                  ></div>
                  <div
                    style={
                      headSlide == 7 && document.body.clientWidth > 640
                        ? {
                            width: "0.8vw",
                            height: "0.8vw",
                            background: "#6A7080",
                          }
                        : headSlide == 7 && document.body.clientWidth < 640
                        ? {
                            width: "4.5vw",
                            height: "4.5vw",
                            background: "#6A7080",
                          }
                        : {}
                    }
                  ></div>
                </div>
              </Zoom>
              <Rotate bottom right>
                <img src="./img/hand.png" className="hands" alt="" />
              </Rotate>
            </div>
          )}
        </div>
      </header>
      <div className="newBlock">
        <h2>
          <span> Что такое</span>
          Интернет-витрина.рф?
        </h2>
        <img
          className="tuda"
          src={document.body.clientWidth > 640 ? tuda : tudam}
          alt=""
        />
        <div className="lamp">
          <Zoom left>
            <img src={lamp} alt="" />
          </Zoom>
          <div className="lampLeft">
            <p>Платформа универсальна для 95% бизнесов</p>
            <h3>
              Стоимость на рынке такого решения более 1.500.000 ₽{" "}
              <span>У нас в 10 раз дешевле!</span>
            </h3>
          </div>
        </div>
        <img
          src={document.body.clientWidth > 640 ? sht : shm}
          className="ork"
          alt=""
        />
        <video controls className="vid" poster="/img/pref.jpg">
          <source src="/img/sf.mp4" type="video/mp4" />
        </video>
      </div>
      <div className="complex">
        <h2>
          цифровая витрина <span>“На стероидах”!</span>
        </h2>
        <Zoom left cascade>
          <p>В разы превосходящая традиционные решения</p>
        </Zoom>
        {document.body.clientWidth > 640 && (
          <div>
            <Zoom>
              <img src="./img/comp.png" className="comp" alt="" />
            </Zoom>
            <Fade>
              <img src="./img/plak.svg" className="cent" alt="" />
            </Fade>
          </div>
        )}
        {document.body.clientWidth < 640 && (
          <div className="mobAd">
            <img src="./img/t1.svg" alt="" />
            <img src="./img/t2.svg" alt="" />
            <img src="./img/t3.svg" alt="" />
            <img src="./img/t4.svg" alt="" />
            <img src="./img/t5.svg" alt="" />
            <img className="t6" src="./img/t6.png" alt="" />
          </div>
        )}
      </div>
      <div className="moduls">
        <h3>
          Интернет-витрина.рф
          <span>
            это фундамент ВАШЕГО <br /> бизнеса в интернете{" "}
          </span>
        </h3>
        <div className="newMod">
          <p>20+</p>
          <div>
            <span>подключаемых модулей</span>
            каждый увеличивает продажи на 3-5%
          </div>
        </div>
        <img
          src={
            document.body.clientWidth > 640
              ? "./img/vtf.png"
              : "./img/mobHH.png"
          }
          className="vtf"
          alt=""
        />
        <div className="showBusiness" onClick={() => setPop(true)}>
          Запустить бизнес онлайн<span class="flare"></span>
        </div>
      </div>
      <div className="lol">
        <div className="leftLol">
          <h2>
            <span>21&nbsp;</span> век <img src="./img/mask.png" alt="" />
          </h2>
          <h2>
            20<span>24&nbsp;</span> год
          </h2>
          <p>
            потребитель сделал
            <span>свой выбор</span>
          </p>
          <Zoom>
            <div className="blob">- интернет</div>
          </Zoom>
          <div className="flexible">
            <img src="./img/xren.png" alt="" />
            <div>
              <p>Интернет-витрина.рф</p>
              <span>
                <b>Ваш шанс</b> максимально просто и бюджетно войти в IT с
                решением премиального уровня
              </span>
            </div>
          </div>
        </div>
        <div className="rightLol">
          <img src="./img/girl.png" alt="" />
        </div>
      </div>
      <div className="helpMe">
        <Fade left>
          <img src="./img/pomogite.png" alt="" />
        </Fade>
        <Zoom left cascade>
          <div className="uls">
            <div>Вы сможете:</div>
            <p>
              <span>1</span>Увеличить продажи и стабилизировать доходы
            </p>
            <p>
              <span>2</span>Собрать готовое решение за 24 часа
            </p>
            <p>
              <span>3</span>Сэкономить 1.000.0000 руб на разработке
            </p>
            <p>
              <span>4</span>Получить веб-портал, работающий 3-5-10 лет
            </p>
            <p>
              <span>5</span>Создать современный имидж компании
            </p>
            <p>
              <span>6</span>Привлечь молодую аудиторию
            </p>
            <p>
              <span>7</span>Повысить лояльность постоянных клиентов
            </p>
            <p>
              <span>8</span>Внедрить удобные способы оплаты и доставки
            </p>
            <p>
              <span>9</span>Облегчить покупку товаров/услуг
            </p>
            <p>
              <span>10</span>Продавать 24/365 и зарабатывать на этом
            </p>
          </div>
        </Zoom>
      </div>
      <div className="car">
        <div className="leftCat">
          <h3>Интернет-витрина.рф</h3>
          <h2>
            <span>Идеально</span>
            <p>подойдет</p>
          </h2>
          <p>
            для рекламного трафика <br /> Яндекс.Директ, Яндекс.Бизнес, <br />
            Telegram Ads и др.
          </p>
          <div className="blob">
            <p>
              За счет ВАУ-эффекта <br /> от вашего ноу-хау проекта
            </p>
          </div>
        </div>
        <div className="rightCar">
          <img src="./img/car.png" alt="" />
          <div className="showBusinesss" onClick={() => setPop(true)}>
            Запустить бизнес онлайн<span class="flare"></span>
          </div>
        </div>
      </div>
      <div className="urbl">
        <div className="urist">
          <div className="leftCat">
            <h2>
              <span>От юриста</span>
              <p>до логиста</p>
            </h2>
            {document.body.clientWidth < 640 && (
              <div className="grCas">Интернет-витрина.рф подходит всем!</div>
            )}
            {document.body.clientWidth > 640 ? (
              <div className="omg">
                <Zoom left cascade>
                  <ul>
                    <li>
                      <p>1</p>
                      <span>Салоны красоты</span>
                    </li>
                    <li>
                      <p>2</p>
                      <span>Фитнес центры</span>
                    </li>
                    <li>
                      <p>3</p>
                      <span>Рестораны и кафе</span>
                    </li>
                    <li>
                      <p>4</p>
                      <span>Цветочные магазины</span>
                    </li>
                    <li>
                      <p>5</p>
                      <span>Ювелирные магазины</span>
                    </li>
                    <li>
                      <p>6</p>
                      <span>Магазины электроники</span>
                    </li>
                    <li>
                      <p>7</p>
                      <span>Мебельные магазины</span>
                    </li>
                    <li>
                      <p>8</p>
                      <span>Автомойки</span>
                    </li>
                    <li>
                      <p>9</p>
                      <span>Химчистки</span>
                    </li>
                    <li>
                      <p>10</p>
                      <span>Автосервисы</span>
                    </li>
                    <li>
                      <p>11</p>
                      <span>Веб-студии</span>
                    </li>
                    <li>
                      <p>12</p>
                      <span>Ремонтные услуги</span>
                    </li>
                    <li>
                      <p>13</p>
                      <span>Медицинские центры</span>
                    </li>
                    <li>
                      <p>14</p>
                      <span>Юридические фирмы</span>
                    </li>
                  </ul>
                </Zoom>
                <Zoom right cascade>
                  <ul>
                    <li>
                      <p>15</p>
                      <span>Магазины одежды и обуви</span>
                    </li>
                    <li>
                      <p>16</p>
                      <span>Образовательные услуги</span>
                    </li>
                    <li>
                      <p>17</p>
                      <span>Риелторские услуги</span>
                    </li>
                    <li>
                      <p>18</p>
                      <span>Клининговые компании</span>
                    </li>
                    <li>
                      <p>19</p>
                      <span>Охранные предприятия</span>
                    </li>
                    <li>
                      <p>20</p>
                      <span>Типографии</span>
                    </li>
                    <li>
                      <p>21</p>
                      <span>PR и рекламные агентства</span>
                    </li>
                    <li>
                      <p>22</p>
                      <span>Логистические компании</span>
                    </li>
                    <li>
                      <p>23</p>
                      <span>Фото/видео услуги</span>
                    </li>
                    <li>
                      <p>24</p>
                      <span>Организация праздников</span>
                    </li>
                    <li>
                      <p>25</p>
                      <span>Продажи цифровых товаров</span>
                    </li>
                    <li>
                      <p>26</p>
                      <span>Стоматологические клиники</span>
                    </li>
                    <li>
                      <p>27</p>
                      <span>Туристические агентства</span>
                    </li>
                    <li>
                      <p>28</p>
                      <span>И другие...</span>
                    </li>
                  </ul>
                </Zoom>
              </div>
            ) : (
              <div className="omg">
                <Swiper
                  grabCursor={true}
                  effect={"creative"}
                  creativeEffect={{
                    prev: {
                      shadow: false,
                      translate: ["-120%", 0, -500],
                    },
                    next: {
                      shadow: false,
                      translate: ["120%", 0, -500],
                    },
                  }}
                  modules={[EffectCreative]}
                  className="mySwiperM1"
                  onActiveIndexChange={(index) => setsl2(index.activeIndex)}
                >
                  <SwiperSlide>
                    <ul>
                      <li>
                        <p>1</p>
                        <span>Салоны красоты</span>
                      </li>
                      <li>
                        <p>2</p>
                        <span>Фитнес центры</span>
                      </li>
                      <li>
                        <p>3</p>
                        <span>Рестораны и кафе</span>
                      </li>
                      <li>
                        <p>4</p>
                        <span>Цветочные магазины</span>
                      </li>
                      <li>
                        <p>5</p>
                        <span>Ювелирные магазины</span>
                      </li>
                      <li>
                        <p>6</p>
                        <span>Магазины электроники</span>
                      </li>
                      <li>
                        <p>7</p>
                        <span>Мебельные магазины</span>
                      </li>
                      <li>
                        <p>8</p>
                        <span>Автомойки</span>
                      </li>
                      <li>
                        <p>9</p>
                        <span>Химчистки</span>
                      </li>
                      <li>
                        <p>10</p>
                        <span>Автосервисы</span>
                      </li>
                      <li>
                        <p>11</p>
                        <span>Веб-студии</span>
                      </li>
                      <li>
                        <p>12</p>
                        <span>Ремонтные услуги</span>
                      </li>
                      <li>
                        <p>13</p>
                        <span>Медицинские центры</span>
                      </li>
                      <li>
                        <p>14</p>
                        <span>Юридические фирмы</span>
                      </li>
                    </ul>
                  </SwiperSlide>
                  <SwiperSlide>
                    <ul>
                      <li>
                        <p>15</p>
                        <span>Магазины одежды и обуви</span>
                      </li>
                      <li>
                        <p>16</p>
                        <span>Образовательные услуги</span>
                      </li>
                      <li>
                        <p>17</p>
                        <span>Риелторские услуги</span>
                      </li>
                      <li>
                        <p>18</p>
                        <span>Клининговые компании</span>
                      </li>
                      <li>
                        <p>19</p>
                        <span>Охранные предприятия</span>
                      </li>
                      <li>
                        <p>20</p>
                        <span>Типографии</span>
                      </li>
                      <li>
                        <p>21</p>
                        <span>PR и рекламные агентства</span>
                      </li>
                      <li>
                        <p>22</p>
                        <span>Логистические компании</span>
                      </li>
                      <li>
                        <p>23</p>
                        <span>Фото/видео услуги</span>
                      </li>
                      <li>
                        <p>24</p>
                        <span>Организация праздников</span>
                      </li>
                      <li>
                        <p>25</p>
                        <span>Продажи цифровых товаров</span>
                      </li>
                      <li>
                        <p>26</p>
                        <span>Стоматологические клиники</span>
                      </li>
                      <li>
                        <p>27</p>
                        <span>Туристические агентства</span>
                      </li>
                      <li>
                        <p>28</p>
                        <span>И другие...</span>
                      </li>
                    </ul>
                  </SwiperSlide>
                </Swiper>
                <div className="dotser">
                  {[1, 1].map((em, index) => {
                    return (
                      <div
                        style={
                          index == sl2
                            ? {
                                width: "5vw",
                                height: "5vw",
                                background: "#3e95ff",
                              }
                            : {}
                        }
                      ></div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
          <img
            src={
              document.body.clientWidth > 640
                ? "./img/map.png"
                : "./img/mobMap.png"
            }
            alt=""
          />
        </div>
        <div className="pls">
          <p>Интернет-витрина.рф </p>
          <span>
            это масштабируемое высокотехнологичное решение для любого типа
            бизнеса не теряющее актуальности и{" "}
            <b>адаптируемое к долгосрочному использованию на 3-5-10 лет</b>, с
            возможностью частого обновления дизайна
          </span>
        </div>
        <div className="showBusinesss" onClick={() => setPop(true)}>
          Запустить бизнес онлайн<span class="flare"></span>
        </div>
      </div>
      <div className="tarifs">
        <h2>
          <span> ОДИН ТАРИФ</span>
          все включено
        </h2>
        <div className="provBody">
          <div className="prov">PRO-ВЕРСИЯ</div>
          <div className="provPrice">
            <p>100.000 руб</p>
            <div>
              <span>250.000 руб </span>
              (будущая цена)
            </div>
          </div>
          <div className="provR">
            Готовое IT-решение для бизнеса <br /> Вы собственник навсегда
          </div>
        </div>
        {/* <div className="trEms">
          {document.body.clientWidth > 640 ? (
            <Fade top cascade>
              <img onClick={() => setPop(true)} src="./img/tr1.svg" alt="" />
              <img onClick={() => setPop(true)} src="./img/tr2.svg" alt="" />
              <img onClick={() => setPop(true)} src="./img/tr3.svg" alt="" />
              <img onClick={() => setPop(true)} src="./img/tr4.svg" alt="" />
            </Fade>
          ) : (
            <div>
              <div className="dotser">
                {[1, 1, 1, 1].map((em, index) => {
                  return (
                    <div
                      style={
                        index == sl3
                          ? {
                              width: "5vw",
                              height: "5vw",
                              background: "#3e95ff",
                            }
                          : {}
                      }
                    ></div>
                  );
                })}
              </div>
              <Swiper
                grabCursor={true}
                effect={"creative"}
                creativeEffect={{
                  prev: {
                    shadow: false,
                    translate: ["-120%", 0, -500],
                  },
                  next: {
                    shadow: false,
                    translate: ["120%", 0, -500],
                  },
                }}
                modules={[EffectCreative]}
                className="mySwiperM2"
                onActiveIndexChange={(index) => setsl3(index.activeIndex)}
              >
                <SwiperSlide>
                  <img
                    onClick={() => setPop(true)}
                    src="./img/tr1.svg"
                    alt=""
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    onClick={() => setPop(true)}
                    src="./img/tr2.svg"
                    alt=""
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    onClick={() => setPop(true)}
                    src="./img/tr3.svg"
                    alt=""
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    onClick={() => setPop(true)}
                    src="./img/tr4.svg"
                    alt=""
                  />
                </SwiperSlide>
              </Swiper>
            </div>
          )}
        </div>*/}
      </div>
      {show && document.body.clientWidth > 5000 && (
        <div className="arenda">
          <h3>
            Не аренда
            <span> А Ваша собственность!</span>
          </h3>
          {document.body.clientWidth < 640 && (
            <div className="kos">
              {" "}
              <svg
                className="stlft"
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.833333"
                  y="0.942708"
                  width="18.3333"
                  height="18.3333"
                  rx="9.16667"
                  stroke="#0370F3"
                  stroke-width="1.66667"
                />
                <path
                  d="M8.33398 6.35938L12.5007 10.526L8.33398 14.6927"
                  stroke="black"
                  stroke-width="1.66667"
                />
              </svg>
              Листайте{" "}
              <svg
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.833333"
                  y="0.942708"
                  width="18.3333"
                  height="18.3333"
                  rx="9.16667"
                  stroke="#0370F3"
                  stroke-width="1.66667"
                />
                <path
                  d="M8.33398 6.35938L12.5007 10.526L8.33398 14.6927"
                  stroke="black"
                  stroke-width="1.66667"
                />
              </svg>
            </div>
          )}
          {document.body.clientWidth > 640 ? (
            <Zoom>
              <img src={ge} alt="" className="get" />
            </Zoom>
          ) : (
            <Swiper
              grabCursor={true}
              effect={"creative"}
              creativeEffect={{
                prev: {
                  shadow: false,
                  translate: ["-120%", 0, -500],
                },
                next: {
                  shadow: false,
                  translate: ["120%", 0, -500],
                },
              }}
              onInit={(e) => {
                e.slideNext();
              }}
              modules={[EffectCreative]}
              className="mySwiperM4"
              activeIndex={2}
            >
              <SwiperSlide>
                <img src={sls2} />
              </SwiperSlide>
              <SwiperSlide>
                <img src={sls1} />
              </SwiperSlide>
              <SwiperSlide>
                <img src={sls3} />
              </SwiperSlide>
            </Swiper>
          )}
          <h4>
            <span>Всего 3 простых шага,</span> чтобы начать продавать онлайн:
          </h4>
          {document.body.clientWidth > 640 ? (
            <img src="./img/gog.svg" className="get gett" alt="" />
          ) : (
            <img src={gogm} />
          )}
          <div className="showBusinesss" onClick={() => setPop(true)}>
            Запустить бизнес онлайн<span class="flare"></span>
          </div>
        </div>
      )}
      <div className="com">
        <h3>
          <span>команда</span>и опыт
        </h3>
        {document.body.clientWidth > 640 ? (
          <div className="comBody">
            <Zoom top left>
              <img src="./img/k1.png" alt="" />
            </Zoom>
            <Zoom top right>
              <img src="./img/k2.png" alt="" />
            </Zoom>
            <Zoom bottom left>
              <img src="./img/k3.png" alt="" />
            </Zoom>
            <Zoom bottom right>
              <img src="./img/k4.png" alt="" />
            </Zoom>
          </div>
        ) : (
          <div>
            <Swiper
              grabCursor={true}
              effect={"creative"}
              creativeEffect={{
                prev: {
                  shadow: false,
                  translate: ["-120%", 0, -500],
                },
                next: {
                  shadow: false,
                  translate: ["120%", 0, -500],
                },
              }}
              modules={[EffectCreative]}
              className="mySwiperM1"
              onActiveIndexChange={(index) => setsl4(index.activeIndex)}
            >
              <SwiperSlide>
                <img src="./img/k1.png" alt="" />
                <img src="./img/k2.png" alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <img src="./img/k3.png" alt="" />
                <img src="./img/k4.png" alt="" />
              </SwiperSlide>
            </Swiper>
            <div className="dotser">
              {[1, 1].map((em, index) => {
                return (
                  <div
                    style={
                      index == sl4
                        ? {
                            width: "5vw",
                            height: "5vw",
                            background: "#3e95ff",
                          }
                        : {}
                    }
                  ></div>
                );
              })}
            </div>
          </div>
        )}
      </div>
      <div className="clients">
        <Slide left cascade>
          <h3>Клиенты</h3>
        </Slide>
        <div className="swp">
          {document.body.clientWidth > 640 ? (
            <Swiper
              grabCursor={true}
              effect={"creative"}
              creativeEffect={{
                prev: {
                  shadow: false,
                  translate: ["-120%", 0, -500],
                },
                next: {
                  shadow: false,
                  translate: ["120%", 0, -500],
                },
              }}
              modules={[EffectCreative]}
              className="mySwiper2"
              onActiveIndexChange={(index) => setsl5(index.activeIndex)}
            >
              <SwiperSlide>
                <img
                  src={
                    document.body.clientWidth > 640
                      ? "./img/p1.png"
                      : "./img/ps1.png"
                  }
                  alt=""
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src={
                    document.body.clientWidth > 640
                      ? "./img/p2.png"
                      : "./img/ps2.png"
                  }
                  alt=""
                />
              </SwiperSlide>
            </Swiper>
          ) : (
            <Swiper
              grabCursor={true}
              effect={"creative"}
              creativeEffect={{
                prev: {
                  shadow: false,
                  translate: ["-120%", 0, -500],
                },
                next: {
                  shadow: false,
                  translate: ["120%", 0, -500],
                },
              }}
              modules={[EffectCreative]}
              className="mySwiper2"
              onActiveIndexChange={(index) => setsl5(index.activeIndex)}
            >
              <SwiperSlide>
                <img
                  src={
                    document.body.clientWidth > 640
                      ? "./img/p1.png"
                      : "./img/ps1.png"
                  }
                  alt=""
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src={
                    document.body.clientWidth > 640
                      ? "./img/p2.png"
                      : "./img/ps2.png"
                  }
                  alt=""
                />
              </SwiperSlide>
              <SwiperSlide>
                <img src={"./img/ps3.png"} alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={"./img/ps4.png"} alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={"./img/ps5.png"} alt="" />
              </SwiperSlide>
            </Swiper>
          )}
          <div className="dotser">
            {document.body.clientWidth > 640
              ? [1, 1].map((em, index) => {
                  return (
                    <div
                      style={
                        index == sl5
                          ? {
                              width: "5vw",
                              height: "5vw",
                              background: "#3e95ff",
                            }
                          : {}
                      }
                    ></div>
                  );
                })
              : [1, 1, 1, 1, 1].map((em, index) => {
                  return (
                    <div
                      style={
                        index == sl5
                          ? {
                              width: "5vw",
                              height: "5vw",
                              background: "#3e95ff",
                            }
                          : {}
                      }
                    ></div>
                  );
                })}
          </div>
        </div>
        <Zoom>
          <div className="pls">
            <p>И многие, многие другие </p>
            <span>
              Есть опыт работы как с гигантами российского бизнеса, так и с
              небольшими региональными компаниями
            </span>
          </div>
        </Zoom>
        <div className="showBusinesss" onClick={() => setPop(true)}>
          Запустить бизнес онлайн<span class="flare"></span>
        </div>
      </div>
      <div className="botBoost">
        <div className="botLeft">
          <h2>Подписывайтесь</h2>
          <p>
            на наш Телеграм бот
            <span>и получите подарок</span>
          </p>
          <h3>+ Вы получите тонну полезного контента</h3>
          <span>
            и скидку на услуги <br />
            внутренней веб-студии
          </span>
          <Zoom left>
            <div className="proc">
              <span>до</span> 40%
            </div>
          </Zoom>
          <div className="upSSS">
            <div
              className="showBusinesss"
              onClick={() =>
                window.open("https://t.me/internetvitrina_bot", "_blank")
              }
            >
              Получить скидку и подарок<span class="flare"></span>
            </div>
            <p>Обещаем никакого спама</p>
          </div>
        </div>
        <div className="botRight">
          <img src="./img/woman.png" alt="" />
        </div>
      </div>
      <div className="showFooter">
        <div className="showFooterBody">
          <img src={logo} alt="" />
          <p>Контакты</p>
          <div className="addLink">
            <p>Телефон:</p>
            <a href="tel:+7 (925) 333 83-63">
              +7 (925) 333 83-63
              <img src={ph} alt="" />
            </a>
          </div>
          <div className="addLink">
            <p>Мессенджеры:</p>
            <div>
              <a href="https://t.me/internetvitrina" target="_blank">
                Telegramm <img src={tg} alt="" />
              </a>
              <a href="https://wa.me/79253338363" target="_blank">
                Whatsapp <img src={wt} alt="" />
              </a>
            </div>
          </div>
          <div className="addLink">
            <p>Время работы:</p>
            <a>
              с 9:00 до 21:00, пн-вс <img src={tim} alt="" />
            </a>
          </div>
        </div>
        <div className="footerLinks" style={{ display: "none" }}>
          <a href="">Политика конфиденциальности</a>
          <a href="">Согласие с рассылкой</a>
          <a href="">Отказ от ответственности</a>
          <span>*Предложение не является публичной офертой</span>
        </div>
      </div>
    </div>
  );
}
